<template>
  <div style="width: 100%;">
    <v-container>
      <v-row>
        <v-col class="d-flex flex-row align-center">
          <v-btn @click="$router.go(-1)" text><v-icon>mdi-arrow-left</v-icon></v-btn>
          <h1>Commissions</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h3>Filters</h3>
          <span class="d-flex flex-row">
            <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="filters.startDate"
                    label="Start Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                />
              </template>
              <v-date-picker
                  v-model="filters.startDate"
              />
            </v-menu>
            <v-btn @click="filters.endDate=filters.startDate" color="warning" class="mx-2 mt-2"><v-icon>mdi-arrow-right</v-icon></v-btn>
            <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="filters.endDate"
                    label="End Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                />
              </template>
              <v-date-picker
                  v-model="filters.endDate"
              />
            </v-menu>
            <v-btn :loading="loader" class="mt-2 ml-2" fab small :disabled="!filters.startDate || !filters.endDate" color="info" @click="fetchReport"><v-icon>mdi-check</v-icon></v-btn>
            <v-spacer/>
            <v-spacer/>
          </span>
        </v-col>
      </v-row>
      <v-row v-if="data && data.length===0">
        <v-col>
          <span class="red--text">No records found with the provided filters.</span>
        </v-col>
      </v-row>
      <v-row v-if="data">
        <v-col>
          <span><span class="text-h6">Showing records for </span><span class="text-h4">{{utils.parseDate(filters.startDate, 2)}} - {{utils.parseDate(filters.endDate, 2)}}</span></span>
        </v-col>
      </v-row>
      <v-row v-if="totals">
        <v-col>
          <v-card @click="$router.push(`/users/view/${top.id}`)">
            <v-card-text>
              <h3>Top CSR by Commission</h3>
              <span><h1 class="mr-1">{{top.name}}</h1> {{utils.formatCurrency(top.amt)}}</span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card>
            <v-card-text>
              <h3>Total Commission</h3>
              <h1>{{utils.formatCurrency(totals.commission)}}</h1>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card>
            <v-card-text>
              <h3>Total Paid</h3>
              <h1>{{utils.formatCurrency(totals.paid)}}</h1>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card>
            <v-card-text>
              <h3>Total Outstanding</h3>
              <h1>{{utils.formatCurrency(totals.outstanding)}}</h1>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="data && data.length>0">
        <v-col>
          <v-card outlined>
            <v-card-text>
              <v-row>
                <v-col><b>Employee</b></v-col>
                <v-col><b>Order</b></v-col>
                <v-col><b>Date</b></v-col>
                <v-col><b>Amount</b></v-col>
                <v-col><b>Order Commission</b></v-col>
                <v-col><b>Total</b></v-col>
                <v-col><b>Outstanding</b></v-col>
              </v-row>
              <span v-for="(user, i) of data" :key="i">
                <span v-if="user.csrUser.length>0">
                  <v-row style="margin-bottom: -65px;" v-for="(order, j) of user.csrUser" :key="j">
                    <v-col v-if="j===0">
                      <p style="cursor: pointer;" @click="$router.push(`/users/view/${user.id}`)"><span class="text-h6">{{user.firstName}} {{user.lastName?user.lastName:''}}</span> (ID: {{user.id}})</p>
                    </v-col>
                    <v-col v-else></v-col>
                    <v-col><p @click="$router.push(`/orders/view/${order.id}`)" style="cursor: pointer;">#{{order.id}}</p></v-col>
                    <v-col>{{utils.parseDate(order.sealedAt, 1)}}</v-col>
                    <v-col>{{utils.formatCurrency(order.metadata.grandTotal)}}</v-col>
                    <v-col class="d-flex flex-row"><h3 class="mr-1">{{utils.formatCurrency(order.commission)}}</h3> ({{order.metadata.commissionRate?order.metadata.commissionRate+'%':'None'}})</v-col>
                    <v-col></v-col>
                    <v-col></v-col>
                  </v-row>
                  <v-row style="margin-bottom: 20px;">
                    <v-col></v-col>
                    <v-col></v-col>
                    <v-col></v-col>
                    <v-col></v-col>
                    <v-col></v-col>
                    <v-col><h3>{{utils.formatCurrency(user.commission)}}</h3></v-col>
                    <v-col><h2 :class="user.paid<user.commission?'red--text':'green--text'">{{utils.formatCurrency(user.commission-user.paid)}}</h2></v-col>
                  </v-row>
                  <hr>
                </span>
              </span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import axios from 'axios';
  import {mapGetters} from "vuex";
  import utils from "./../../plugins/helpers"
  export default {
    data () {
      return {
        utils: utils,
        deleteDialog: false,
        deleteConfirmed: true,
        loader: false,
        snackObj: {
          state: false,
          color: '',
          text: ''
        },

        filters: {
          startDate: null,
          endDate: null
        },

        top: {},
        totals: null,
        data: null,
      }
    },
    async mounted(){
        await this.getAllData();
    },
    computed: {
      ...mapGetters(['getEndpoint', "isAllowed", 'lookupUsername']),
    },
    methods: {
      rowClick(row){
        this.$router.push({ path: `/orders/view/${row.id}`})
      },
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      async getAllData(){
        try {
          this.loader = true;

          let now = new Date();
          let firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
          let lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);

          this.filters.startDate = this.utils.parseDate(firstDay);
          this.filters.endDate = this.utils.parseDate(lastDay);

        } catch (error) {
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.loader = false;
        }
      },
      async fetchReport(){
        try {
          this.loader = true;
          this.data = null;
          this.totals = null;

          let res = await axios.post(`${this.getEndpoint}/api/reporting/commissions`, this.filters)
          if(res.data.error) throw res.data.error
          if(res.data.data.length===0) throw "No records found within the provided filters."

          this.data = res.data.data;
          this.totals = res.data.totals;
          this.top = res.data.top;

        } catch (error) {
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.loader = false;
        }
      }
    }
  }
</script>
