<template>
  <div class="" style="width: 100%;">
    <v-row>
      <v-col>
        <v-container fluid>
          <v-row>
            <v-col class="d-flex flex-row align-center">
              <v-btn text @click="$router.go(-1)">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
              <h1>Orders - Filtered By Product</h1>
              <v-progress-circular
                  indeterminate
                  color="green"
                  v-if="loader"
                  style="margin-left: 10px;"
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="">
              <span class="d-flex flex-column">
                <span class="d-flex flex-row">
                  <span><v-select dense hide-details @change="filterOrders" v-model="search.status" label="Order Status" outlined :items="orderStatuses" item-text="name" item-value="id"/></span>
                  <span class="d-flex flex-row align-center flex-fill ml-2">
                    <v-text-field :loading="search.loading" v-model="searchP" @change="searchProducts" clearable hide-details outlined dense label="Search for Products..."/>
                    <v-btn :loading="search.loading" fab x-small class="ml-2" color="info" @click="searchProducts"><v-icon>mdi-magnify</v-icon></v-btn>
                  </span>
                </span>
                <v-card outlined class="d-flex flex-column mt-1">
                  <v-card-title>Search Results</v-card-title>
                  <v-card-text>
                    <span v-for="(prod, i) of search.items" :key="i" class="d-flex flex-row align-center">
                      <span>{{prod.name}}</span>
                      <v-btn fab x-small class="ml-2" color="info" @click="addToSearch(prod)"><v-icon>mdi-plus</v-icon></v-btn>
                    </span>
                  </v-card-text>
                </v-card>
                <v-card outlined class="mt-5">
                  <v-card-title>Products In Search</v-card-title>
                  <v-card-text>
                    <span v-for="(prod, i) of search.selected" :key="i" class="d-flex flex-column">
                      <hr>
                      <span class="d-flex flex-row align-center">
                        <v-list-item-avatar>
                          <img :src="findProductImage(prod)" alt="">
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title v-text="prod.name"></v-list-item-title>
                          <v-list-item-subtitle v-text="utils.formatCurrency(prod.regularPrice)"></v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>ID: {{prod.id}}</v-list-item-action>
                          <!--                      <span>{{prod.name}}</span>-->
                        <v-btn fab x-small class="ml-2" color="error" @click="removeFromSearch(prod)"><v-icon>mdi-close</v-icon></v-btn>
                      </span>
                    </span>
                  </v-card-text>
                </v-card>
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="!loader">
              <v-data-table
                  :headers="headers"
                  :items="allData"
                  :single-expand="singleExpand"
                  :expanded.sync="expanded"
                  :options.sync="options"
                  :server-items-length="pagination.dataCount"
                  :loading="pagination.loading"
                  show-expand
                  class="elevation-0"
              >
                <template v-slot:item.customerName="{ item }">
                  <span>{{ item.customerName?item.customerName:"-" }}</span>
                </template>
                <template v-slot:item.csrUser="{ item }">
                  <span v-if="item.createdBy">{{lookupUsername(item.createdBy)}}</span>
                  <span v-else>-</span>
                </template>
                <template v-slot:item.status="{ item }">
                  <v-chip :color="utils.getStatusColor(item.status)">{{utils.parseStatus(item.status)}}</v-chip>
                </template>
                <template v-slot:item.createdAt="{ item }">
                  <span>{{utils.formatDate(item.createdAt, 'short')}}</span>
                </template>
                <template v-slot:item.metadata.grandTotal="{ item }">
                  <span>{{utils.formatCurrency(item.metadata.grandTotal)}}</span>
                </template>
                <template v-slot:item.actions="{ item }">
                  <span>
                    <v-btn fab x-small color="info" @click="rowClick(item)"><v-icon>mdi-arrow-right</v-icon></v-btn>
                  </span>
                </template>
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title v-if="search.items.length>0">Found {{pagination.dataCount}} results matching your search.</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-switch
                        v-model="singleExpand"
                        label="Single expand"
                        class="mt-2"
                    ></v-switch>
                  </v-toolbar>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length" style="padding: 10px;">
                    <v-data-table
                        :items="item.OrderLineItems"
                        :headers="lineItemHeaders"
                        class="elevation-0"
                        style="width: 100%; padding: 0; margin: 0;"
                        :items-per-page="-1"
                        hide-default-footer
                        :item-class="productRowBackground"
                    >
                      <template v-slot:item.unitPrice="{ item }">
                        <span>{{utils.formatCurrency(item.unitPrice)}}</span>
                      </template>
                      <template v-slot:item.total="{ item }">
                        <span>{{utils.formatCurrency((parseFloat(item.unitPrice)*parseFloat(item.quantity)))}}</span>
                      </template>
                    </v-data-table>
                  </td>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>
<style>
.highlightRow{
  background: #8deaff;
}
</style>
<script>
  import axios from 'axios';
  import {mapGetters} from "vuex";
  import utils from "./../../plugins/helpers"
  export default {
    data () {
      return {
        utils:utils,
        loader: false,
        max25chars: v => v.length <= 25 || 'Input too long!',

        headers: [
          {
            text: 'Order #',
            align: 'start',
            sortable: true,
            value: 'id',
          },
          { text: 'Status', value: 'status' },
          { text: 'Date Created', value: 'createdAt' },
          { text: 'Customer', value: 'customerName', sortable: false },
          { text: 'CSR', value: 'csrUser', sortable: false },
          { text: 'Delivery Type', value: 'deliveryInfo.deliveryType', sortable: false },
          { text: '# Items', value: 'itemCount', sortable: false},
          { text: 'Grand Total', value: 'metadata.grandTotal', sortable: false },
          { text: '', value: 'actions' },
          { text: '', value: 'data-table-expand' },
        ],
        lineItemHeaders: [
          {
            text: 'Product ID',
            align: 'start',
            sortable: false,
            value: 'productId',
          },
          { text: 'SKU', value: 'sku', sortable: false },
          { text: 'Name', value: 'productName', sortable: false },
          { text: 'QTY', value: 'quantity', sortable: false },
          { text: 'Unit Price', value: 'unitPrice', sortable: false },
          { text: 'Line Total', value: 'total', sortable: false },
        ],
        singleExpand: false,
        expanded: [],

        orderStatuses: [
          {name: "All", id: null},
          {name: "Draft", id: 0},
          {name: "Sealed", id: 1},
          // {name: "Admin Sealed", id: 2},
          // {name: "Delivery Scheduled", id: 3},
          // {name: "Pending Reschedule", id: 4},
          // {name: "Out For Delivery", id: 5},
          // {name: "Delivered", id: 6}
        ],

        searchP: null,
        search: {
          status: null,
          items: [],
          loading: false,
          selected: []
        },

        pagination: {
          loading: false,
          dataCount: 0,
        },
        options: {
          sortBy: ['id'],
          sortDesc: [false],
          page: 1,
          itemsPerPage: 15,
          products: []
        },

        allData: [],
      }
    },
    async created(){
      try{
        //used for pagination
        let oldQuery = this.getURLQuery();
        this.options.page = oldQuery.page || 1;
        this.options.itemsPerPage = oldQuery.limit || 15
        this.options.sortBy = [oldQuery.sort || "id"]
        this.options.sortDesc = [oldQuery.order || false]
        this.options.products = oldQuery.products||[]
        this.search.status = oldQuery.status

        for(let prod of this.options.products){
          let res = await axios.get(`${this.getEndpoint}/api/products/${prod}`)
          if(res.data.error) throw res.data.error
          this.search.selected.push(res.data.data)
        }

        if(this.search.selected.length>0) await this.filterOrders();
        //used for pagination end
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }
    },
    async mounted(){

    },
    computed: {
      ...mapGetters(['getEndpoint', 'lookupUsername'])
    },
    watch: {
      options: {
        async handler () {
          await this.filterOrders();
        },
        deep: true,
      }
    },
    methods: {
      productRowBackground: function (item) {
        return this.search.selected.find(x => x.id===item.productId)? 'light-blue white--text' : ''
      },
      async rowClick(row){
        await this.$router.push({path: `/orders/view/${row.id}`});
      },
      async searchProducts(){
        try{
          let val = this.searchP

          if(!val || val.length===0){
            this.search.items = [];
            return
          }

          this.search.loading = true;

          let searchData = {
            val: val
          }

          let uriFields = Object.keys(searchData).map(x => {
            return x + "=" + (searchData[x]!==undefined?encodeURIComponent(searchData[x]):'')
          }).join("&");

          let res = await axios.get(`${this.getEndpoint}/api/products/searchByAllVariationsNoLimit?${uriFields}`)
          if(res.data.error) throw res.data.error
          this.search.items = res.data.data
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
        finally {
          this.search.loading = false;
        }
      },
      addToSearch(prod){
        if(!this.search.selected.find(x => x.id===prod.id)){
          this.search.selected.push(prod)
          this.filterOrders()
        }
      },
      removeFromSearch(prod){
        if(this.search.selected.findIndex(x => x.id===prod.id)>=0){
          this.search.selected.splice(this.search.selected.findIndex(x => x.id===prod.id), 1);
          this.filterOrders()
        }
      },
      findProductImage(item){
        if(item.metadata?.media?.length>0){
          let thumbnail = item.metadata.media.find(x => x.tags.includes("thumbnail"));
          if(!thumbnail) thumbnail=item.metadata.media[0];
          return thumbnail.url
        }
        return "https://static.thenounproject.com/png/3674270-200.png";
      },
      async filterOrders(){
        try {
          //used for pagination
          this.pagination.loading = true;
          let paginationData = {
            page: this.options.page,
            limit: this.options.itemsPerPage,
            sort: (this.options.sortBy.length>0)?this.options.sortBy[0]:"id",
            order: this.options.sortDesc[0],
            products: this.search.selected.map(x => x.id||x),
            status: this.search.status
          }
          let uriFields = Object.keys(paginationData).map(x => {
            return x + "=" + (paginationData[x]!==undefined?encodeURIComponent(paginationData[x]):'')
          }).join("&");
          //used for pagination end

          let res = await axios.get(`${this.getEndpoint}/api/orders/tableDataPaginatedProductFilter?${uriFields}`)
          if(res.data.error) throw res.data.error
          this.allData = res.data.data
          //used for pagination
          this.pagination.dataCount = res.data.total

          let shouldUpdate = false;
          let oldQuery = this.getURLQuery();
          let newQuery = {...paginationData};

          if(Object.keys(newQuery).length!==Object.keys(oldQuery).length) shouldUpdate = true;
          if(!shouldUpdate){
            for(let key of Object.keys(newQuery)){
              if(shouldUpdate) break;

              if(Array.isArray(newQuery[key])){
                if(newQuery[key].length!==oldQuery[key].length){
                  shouldUpdate = true;
                  break;
                }
                for(let i=0;i<newQuery[key].length-1;i++){
                  if(newQuery[key][i]!==oldQuery[key][i]){
                    shouldUpdate = true;
                    break;
                  }
                }
              }
              else if(newQuery[key]!==oldQuery[key]){
                shouldUpdate = true;
              }
            }
          }

          if(shouldUpdate){
            this.updatingRoute = true;
            if(this.$route.path===`/orders/filterByProduct`) await this.$router.replace({path: `/orders/filterByProduct`, query: newQuery});
            this.updatingRoute = false;
          }
          //used for pagination end

        } catch (error) {
          console.error(error);
        } finally {
          this.pagination.loading = false;
          this.loader = false;
        }
      },
      //used for pagination
      getURLQuery(custom=null){
        let oldQuery = custom? {...custom}: {...this.$route.query};
        if(oldQuery.limit) oldQuery.limit = parseInt(oldQuery.limit);
        if(oldQuery.page) oldQuery.page = parseInt(oldQuery.page);
        if(oldQuery.order) oldQuery.order = oldQuery.order==='true';
        if(oldQuery.products) oldQuery.products = Array.isArray(oldQuery.products)?oldQuery.products.map(x => parseInt(x)):[parseInt(oldQuery.products)];
        if(oldQuery.status) oldQuery.status = parseInt(oldQuery.status);
        return oldQuery;
      },
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
    }
  }
</script>
