<template>
  <div style="width: 100%;">
    <v-container fluid>
      <v-row>
        <v-col class="d-flex flex-row align-center">
          <v-btn @click="$router.go(-1)" text><v-icon>mdi-arrow-left</v-icon></v-btn>
          <h1>Customers With Outstanding Balances</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span class="d-flex flex-row align-center">
            <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="filters.endDate"
                    label="End Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                />
              </template>
              <v-date-picker
                  v-model="filters.endDate"
              />
            </v-menu>
            <v-btn @click="fetchReport" :loading="loader" class="ml-2" fab color="info" small><v-icon>mdi-check</v-icon></v-btn>
          </span>
          <span class="d-flex flex-row">
            <v-autocomplete
                class="mx-1"
                :disabled="filters.excludeUser.length>0"
                v-model="filters.users"
                :items="getUserCache"
                :item-text="item=>(item.firstName||'')+' '+(item.lastName||'')"
                item-value="id"
                outlined
                hide-details
                multiple
                label="Select Users"
                clearable
            ></v-autocomplete>
            <v-btn :disabled="!filters.excludeUser && !filters.users" class="mt-2" color="warning" @click="swapUserFields"><v-icon>mdi-swap-horizontal</v-icon></v-btn>
            <v-autocomplete
                class="mx-1"
                :disabled="filters.users.length>0"
                v-model="filters.excludeUser"
                :items="getUserCache"
                multiple
                :item-text="item=>(item.firstName||'')+' '+(item.lastName||'')"
                item-value="id"
                outlined
                hide-details
                label="Exclude Users"
                clearable
            ></v-autocomplete>
          </span>
          <span>
            <v-checkbox v-model="filters.quarterly" label="Show Quarterly Breakdown"/>
          </span>
        </v-col>
      </v-row>
      <v-row v-if="data && data.length===0">
        <v-col>
          <span class="red--text">No records found.</span>
        </v-col>
      </v-row>
      <v-row v-if="data && data.length>0 && !filters.quarterly">
        <v-col>
          <table class="text-left">
            <thead>
            <th>Customer</th>
            <th>Last Payment From Customer</th>
            <th>Invoice #</th>
            <th>Invoice Total</th>
            <th>Invoice Date</th>
            <th>Total Paid on Invoice</th>
            </thead>
            <tbody>
            <template v-for="(c, i) of data">
              <tr :key="'customer'+i">
                <td>
                  <span>({{i+1}}) [ID: {{c.id}}] {{c.name}}<br></span>
                  <span v-if="c.phone">{{c.phone}}<br></span>
                  <span v-if="c.email">{{c.email}}</span>
                </td>
                <td>{{utils.formatDate(c.lastPaid)}}</td>
                <td v-if="c.orders.length>0">{{c.orders[0].id}}</td>
                <td v-if="c.orders.length>0">{{utils.formatCurrency(c.orders[0].metadata?.grandTotal || 0)}}</td>
                <td v-if="c.orders.length>0">{{utils.formatDate(c.orders[0].sealedAt)}}</td>
                <td v-if="c.orders.length>0">{{utils.formatCurrency(c.orders[0].totalPaid)}}</td>
              </tr>
              <template v-for="(o, j) of c.orders">
                <tr :key="o.id+'index'+j" v-if="j!==0">
                  <td></td>
                  <td></td>
                  <td>{{o.id}}</td>
                  <td>{{utils.formatCurrency(o.metadata?.grandTotal || 0)}}</td>
                  <td>{{utils.formatDate(o.sealedAt)}}</td>
                  <td>{{utils.formatCurrency(o.totalPaid)}}</td>
                </tr>
              </template>
            </template>
            </tbody>
          </table>
        </v-col>
      </v-row>
      <v-row v-if="quarters && quarters.length>0 && filters.quarterly">
        <v-col>
          <span class="d-flex flex-column">
            <span>Total Sales: {{utils.formatCurrency(summaries.reduce((a, b) => a+b.sales, 0))}}</span>
            <span>Total Received: {{utils.formatCurrency(summaries.reduce((a, b) => a+b.received, 0))}}</span>
            <span>Total Outstanding: {{utils.formatCurrency(summaries.reduce((a, b) => a+b.outstanding, 0))}}</span>
          </span>
          <span v-for="(q, i) of quarters" :key="i">
            <h2>Q{{i+1}}</h2>
            <span class="d-flex flex-column">
              <h3>Summary</h3>
              <span>Total Sales: {{utils.formatCurrency(summaries[i].sales)}}</span>
              <span>Total Received: {{utils.formatCurrency(summaries[i].received)}}</span>
              <span>Total Outstanding: {{utils.formatCurrency(summaries[i].outstanding)}}</span>
            </span>
            <table class="text-left mb-10">
              <thead>
              <th>Customer</th>
              <th>Last Payment From Customer</th>
              <th>Invoice #</th>
              <th>Invoice Total</th>
              <th>Invoice Date</th>
              <th>Total Paid on Invoice</th>
              </thead>
              <tbody>
              <template v-for="(c, i) of q">
                <tr :key="'customer'+i">
                  <td>
                    <span>({{i+1}}) [ID: {{c.id}}] {{c.name}}<br></span>
                    <span v-if="c.phone">{{c.phone}}<br></span>
                    <span v-if="c.email">{{c.email}}</span>
                  </td>
                  <td>{{utils.formatDate(c.lastPaid)}}</td>
                  <td v-if="c.orders.length>0">{{c.orders[0].id}}</td>
                  <td v-if="c.orders.length>0">{{utils.formatCurrency(c.orders[0].metadata?.grandTotal || 0)}}</td>
                  <td v-if="c.orders.length>0">{{utils.formatDate(c.orders[0].sealedAt)}}</td>
                  <td v-if="c.orders.length>0">{{utils.formatCurrency(c.orders[0].totalPaid)}}</td>
                </tr>
                <template v-for="(o, j) of c.orders">
                  <tr :key="o.id+'index'+j" v-if="j!==0">
                    <td></td>
                    <td></td>
                    <td>{{o.id}}</td>
                    <td>{{utils.formatCurrency(o.metadata?.grandTotal || 0)}}</td>
                    <td>{{utils.formatDate(o.sealedAt)}}</td>
                    <td>{{utils.formatCurrency(o.totalPaid)}}</td>
                  </tr>
                </template>
              </template>
              </tbody>
            </table>
          </span>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<style scoped>
  table{
    border-collapse: collapse;
  }
  td, th{
    border: 1px solid black;
    padding: 2px 5px;
  }
</style>
<script>
  import axios from 'axios';
  import {mapGetters} from "vuex";
  import utils from "../../plugins/helpers"
  export default {
    data () {
      return {
        utils: utils,
        deleteDialog: false,
        deleteConfirmed: true,
        loader: false,
        snackObj: {
          state: false,
          color: '',
          text: ''
        },

        transactionTypes: [],

        filters: {
          endDate: null,
          quarterly: false,
          users: [],
          excludeUser: []
        },

        totals: null,
        data: null,
        quarters: null,
        summaries: null,
      }
    },
    async mounted(){
        await this.getAllData();
    },
    computed: {
      ...mapGetters(['getEndpoint', "isAllowed", 'lookupUsername', 'getUserCache']),
    },
    methods: {
      rowClick(row){
        this.$router.push({ path: `/orders/view/${row.id}`})
      },
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      async getAllData(){
        try {
          this.loader = true;

          let res = await axios.get(`${this.getEndpoint}/api/transactionTypes`)
          if(res.data.error) throw res.data.error

          this.transactionTypes = res.data.data;

          let now = new Date();
          let lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
          this.filters.endDate = this.utils.parseDate(lastDay);

          this.$forceUpdate();

          await this.fetchReport();
        } catch (error) {
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.loader = false;
        }
      },
      async fetchReport(){
        try {
          this.loader = true;
          this.data = null;
          this.totals = null;

          let res = await axios.post(`${this.getEndpoint}/api/reporting/customersOutstandingOnAccount`, this.filters)
          if(res.data.error) throw res.data.error

          if(res.data.data.length===0) throw "No records found."

          this.data = res.data.data.customers;
          this.quarters = res.data.data.quarters;
          this.summaries = res.data.data.quarterSummaries;

          console.log(this.quarters)

        } catch (error) {
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.loader = false;
        }
      },
      async swapUserFields(){
        if(this.filters.users.length>0){
          let temp = this.filters.users;
          this.filters.users = [];
          this.filters.excludeUser = temp;
        }
        else if(this.filters.excludeUser.length>0){
          let temp = this.filters.excludeUser;
          this.filters.excludeUser = [];
          this.filters.users = temp;
        }
      }
    }

  }
</script>
