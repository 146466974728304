<template>
  <div style="width: 100%;">
    <v-container fluid>
      <v-row class="hideOnPagePrint">
        <v-col class="d-flex flex-row align-center">
          <v-btn @click="$router.go(-1)" text><v-icon>mdi-arrow-left</v-icon></v-btn>
          <h1>Order Sales by CSR</h1>
          <v-btn v-if="data && data.length>0" @click="printData" class="ml-2" fab color="info" x-small><v-icon>mdi-printer</v-icon></v-btn>
        </v-col>
      </v-row>
      <v-row class="hideOnPagePrint">
        <v-col>
          <h3>Filters</h3>
          <span class="d-flex flex-row">
            <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="filters.startDate"
                    label="Start Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                />
              </template>
              <v-date-picker
                  v-model="filters.startDate"
              />
            </v-menu>
            <v-btn @click="filters.endDate=filters.startDate" color="warning" class="mx-2 mt-2"><v-icon>mdi-arrow-right</v-icon></v-btn>
            <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="filters.endDate"
                    label="End Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                />
              </template>
              <v-date-picker
                  v-model="filters.endDate"
              />
            </v-menu>
            <v-btn :loading="loader" class="mt-2 ml-2" fab small :disabled="!filters.startDate || !filters.endDate" color="info" @click="fetchReport"><v-icon>mdi-check</v-icon></v-btn>
            <v-spacer/>
          </span>

          <span class="d-flex flex-row">
            <v-btn class="mr-2" color="info" @click="dailyClosingSales">Daily Closing</v-btn>
            <v-btn class="mr-2" color="info" @click="monthlyClosingSales">Monthly Closing</v-btn>
            <v-btn class="mr-2" color="info" @click="yearlyClosingSales">Yearly Closing</v-btn>
          </span>
        </v-col>
      </v-row>
      <v-row v-if="data && data.length===0">
        <v-col>
          <span class="red--text">No records found with the provided filters.</span>
        </v-col>
      </v-row>
      <v-row v-if="data">
        <v-col>
          <span>Showing {{data.length}} CSRs for {{utils.parseDate(filters.startDate, 2)}}{{filters.startDate!==filters.endDate?` - ${utils.parseDate(filters.endDate, 2)}`:''}}</span>
        </v-col>
      </v-row>
      <v-row v-if="data && data.length>0">
        <v-col>
          <table style="width: 100%;">
            <thead>
            <tr>
              <th class="text-left">User</th>
              <th class="text-left">Order</th>
              <th class="text-left">Date</th>
              <th class="text-left">Total</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(user, u) of data" :key="u">
              <td :style="`vertical-align: top; font-size: 14px;`" @click="(user.id?$router.push(`/users/view/${user.id}`):null)">
                <b v-if="user.id">{{lookupUsername(user.id)}}</b> (ID: {{user.id}})
              </td>
              <td :style="`vertical-align: top; font-size: ${getGlobalValue('reportFontSize')?getGlobalValue('reportFontSize'):'16px'};`">
                <span @click="(order.id?$router.push(`/orders/view/${order.id}`):null)" :key="o" v-for="(order, o) of user.sealedByUser">INV #{{order.id}} {{utils.formatCurrency(order.metadata.grandTotal)}}<br></span>
              </td>
              <td :style="`vertical-align: top; font-size: ${getGlobalValue('reportFontSize')?getGlobalValue('reportFontSize'):'16px'};`">
                <span @click="(order.id?$router.push(`/orders/view/${order.id}`):null)" :key="o" v-for="(order, o) of user.sealedByUser">{{utils.formatDate(order.sealedAt)}} {{utils.formatTime(order.sealedAt)}}<br></span>
              </td>
              <td :style="`vertical-align: top; font-size: 14px;`"><b>{{utils.formatCurrency(user.totalSales||0)}}</b></td>
            </tr>
            </tbody>
          </table>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<style scoped>
table{
  border-collapse: collapse;
}
tr{
  border-bottom: 1px solid #ccc;
}
</style>
<script>
import axios from 'axios';
import moment from 'moment'
import {mapGetters} from "vuex";
import utils from "../../plugins/helpers"
export default {
  data () {
    return {
      utils: utils,
      deleteDialog: false,
      deleteConfirmed: true,
      loader: false,
      snackObj: {
        state: false,
        color: '',
        text: ''
      },

      filters: {
        startDate: null,
        endDate: null,
        paymentUser: [],
        excludeUser: [],
        paymentTypes: []
      },

      totals: null,
      userData: null,
      data: null,
    }
  },
  async mounted(){
    await this.getAllData();
  },
  computed: {
    ...mapGetters(['getEndpoint', "isAllowed", 'lookupUsername', 'getUserCache', 'getGlobalValue', 'paymentTypes']),
  },
  methods: {
    rowClick(row){
      this.$router.push({ path: `/orders/view/${row.id}`})
    },
    snack(text, color=""){
      this.snackObj.text = text;
      this.snackObj.state = true;
      this.snackObj.color = color;
    },
    async getAllData(){
      try {
        this.loader = true;

        let now = new Date();
        let firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
        let lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);

        if(this.$route.query.startDate){
          firstDay = new Date(this.$route.query.startDate);
        }
        if(this.$route.query.endDate){
          lastDay = new Date(this.$route.query.endDate);
        }

        this.filters.startDate = this.utils.parseDate(firstDay);
        this.filters.endDate = this.utils.parseDate(lastDay);

        this.$forceUpdate();
      } catch (error) {
        console.error(error);
        this.snack(error.msg || error.msg?.message || error, "error");
      } finally {
        this.loader = false;
      }
    },
    async fetchReport(){
      try {
        this.loader = true;
        this.data = null;
        this.totals = null;
        this.userData = null;

        let res = await axios.post(`${this.getEndpoint}/api/reporting/dailySalesByCSR`, this.filters)
        if(res.data.error) throw res.data.error

        if(!this.$route.query.startDate){
          await this.$router.push({query: {...this.filters}});
        }
        else{
          let changed = false;

          if(Object.keys(this.$route.query).length!==Object.keys(this.filters).length) changed = true;

          if(!changed){
            for(let key of Object.keys(this.$route.query)){
              if(this.filters[key]!=this.$route.query[key] && !Array.isArray(this.filters[key])){
                changed = true;
                break;
              }
              if(Array.isArray(this.filters[key])){
                for(let i=0;i<this.filters[key].length;i++){
                  if(this.filters[key][i]!=this.$route.query[key][i]){
                    changed = true;
                    break;
                  }
                }
              }
            }
          }
          if(changed) await this.$router.replace({query: {...this.filters}});
        }

        if(res.data.data.length===0) throw "No records found within the provided filters."

        this.data = res.data.data;

        console.log(this.data)

      } catch (error) {
        console.error(error);
        this.snack(error.msg || error.msg?.message || error, "error");
      } finally {
        this.loader = false;
      }
    },
    async dailyClosingSales(){
      this.filters.startDate = moment().format("YYYY-MM-DD");
      this.filters.endDate = moment().format("YYYY-MM-DD");
    },
    async monthlyClosingSales(){
      this.filters.startDate = moment().startOf('month').format("YYYY-MM-DD");
      this.filters.endDate = moment().endOf('month').format("YYYY-MM-DD");
    },
    async yearlyClosingSales(){
      this.filters.startDate = moment().startOf('year').format("YYYY-MM-DD");
      this.filters.endDate = moment().endOf('year').format("YYYY-MM-DD");
    },
    async printData(){
      let els = document.querySelectorAll(".hideOnPagePrint");
      let defaults = [];
      for(let el of els){
        defaults.push(el.style.display);
        el.style.setProperty("display", "none", "important");
      }

      let els2 = document.querySelectorAll(".adjustForPagePrint");
      let defaults2 = [];
      // let defaults3 = [];
      for(let el of els2){
        defaults2.push(el.style.padding);
        // defaults3.push(el.style.paddingRight);
        el.style.setProperty("padding", "0px 50px 0px 0px");
        // el.style.setProperty("paddingRight", "20px");
      }

      window.print();

      for(let i=0;i<els.length;i++){
        els[i].style.setProperty("display", defaults[i], "");
      }

      for(let i=0;i<els2.length;i++){
        els2[i].style.setProperty("padding", defaults2[i], "");
      }
    },
  }

}
</script>
